import React from 'react'
import { graphql } from 'gatsby'
import NavLayout from '../components/NavLayout.js'
import PageTitle from '../components/PageTitle.js'
import styles from './galleries.module.scss'

const Galleries = ({ data }) => (
  <NavLayout>
    <PageTitle title="Galleries" />
    <div className={styles.gallerieslist}>
      {data.allStrapiGallery.edges.map(gallery => {
        const {
          Address_number_and_street,
          City_and_State,
          Name,
          Phone_number,
          Website,
        } = gallery.node
        console.log(Website)
        return (
          <div key={Name} className={styles.gallery}>
            <h2 className={styles.name}>{Name}</h2>
            <address className={styles.address}>
              <div>{Address_number_and_street}</div>
              <div>{City_and_State}</div>
              {Phone_number ? (
                <a className={styles.link} href={`tel:+${Phone_number}`}>
                  {Phone_number}
                </a>
              ) : null}
              {Website ? (
                <a className={styles.link} href={Website}>
                  Website
                </a>
              ) : null}
            </address>
          </div>
        )
      })}
    </div>
  </NavLayout>
)

export default Galleries

export const query = graphql`
  query {
    allStrapiGallery {
      edges {
        node {
          id
          Name
          Website
          Address_number_and_street
          City_and_State
          Phone_number
        }
      }
    }
  }
`
